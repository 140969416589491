<template>
  <div>
    <v-btn-toggle v-model="filterValue" mandatory color="primary" dense>
      <v-btn data-cy="qualification-filter-qualified" :value="qualified">{{ qualifiedText }} </v-btn>
      <v-btn data-cy="qualification-filter-all" :value="all">{{ allText }}</v-btn>
    </v-btn-toggle>
  </div>
</template>
<script>
import { QualificationFilterValues } from "@/helpers/qualificationFilter";

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    qualifiedText: {
      type: String,
      required: true
    },
    allText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filterValue: this.value,
      qualified: QualificationFilterValues.Qualified,
      all: QualificationFilterValues.All
    }
  },
  watch: {
    filterValue:{
      handler() {
        this.$emit('input', this.filterValue)
      }
    },
    value: {
      handler() {
        this.filterValue = this.value
      }
    }
  }
}
</script>
<style scoped></style>
