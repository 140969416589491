import {filterManagement} from "@/services/filters"
import { mapActions} from 'vuex'
import debounce from "lodash.debounce";

export default {
  data() {
    return {
      candidatesFilter: filterManagement.getCandidatesFilterFromUrlQuery(this.$route),
      debounceCandidateSearch: null,
      candidateSearch: null,
      isCandidatesLoading: false,
      candidateNames: [],
      selectedCandidateObjs: [],
    }
  },
  methods: {
    ...mapActions('candidates', ['getCandidateNames','getCandidateNamesById','getCandidateNamesByIds']),
    handleCandidateFilterChanged() {
      filterManagement.resetPagination(this.options)
      filterManagement.setCandidatesFilterInUrlQuery(this.candidatesFilter)
      this.debounceGetDataFromApi()
    },
    getCandidateNamesFromAPI(){
      if (this.isCandidatesLoading) return
      this.isCandidatesLoading = true

      if (this.candidateSearch.length < 2) {
        this.isCandidatesLoading = false
      } else {
        this.getCandidateNames(this.candidateSearch)
          .then(res => {
            this.candidateNames = res.concat(this.selectedCandidateObjs)
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isCandidatesLoading = false))
      }
    },
    updateCandidatesSelection(ids) {
      // Only to keep previous selections on new searches
      this.selectedCandidateObjs = []
      this.candidateNames.forEach((obj) => {
        if (ids.includes(obj.id)) {
          this.selectedCandidateObjs.push(obj)
        }
      })

      this.candidatesFilter = ids
      this.handleCandidateFilterChanged()
    },
    handleCandidateFilterOnNavigatingBack(event) {
      if (event?.type === 'popstate') {
        this.isNavigatingBack = true
        this.options = filterManagement.getTableOptionsFromUrlQuery(this.$route)
        this.candidatesFilter = filterManagement.getCandidatesFilterFromUrlQuery(this.$route)
        this.employersFilter = filterManagement.getEmployersFilterFromUrlQuery(this.$route)
        this.debounceGetDataFromApi()
      }
    },
  },
  async mounted() {
    this.debounceCandidateSearch = debounce(this.getCandidateNamesFromAPI, 600)
    window.addEventListener("popstate", this.handleCandidateFilterOnNavigatingBack);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.handleCandidateFilterOnNavigatingBack);
  },
  watch: {
    candidateSearch: {
      handler() {
        this.debounceCandidateSearch()
      },
      deep: false,
    },
    candidatesFilter: {
      async handler() {
        let candidates = []
        if (this.candidatesFilter && this.candidatesFilter.length > 0)
          candidates = await this.getCandidateNamesByIds(this.candidatesFilter)

        this.selectedCandidateObjs = candidates
        this.candidateNames = candidates
      },
      immediate: true
    },
  }
}
