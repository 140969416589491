import {filterManagement} from "@/services/filters"

export default {
  data() {
    return {
      qualificationFilter: filterManagement.getQualificationFilterFromUrlQuery(this.$route),
    }
  },
  mounted() {
    window.addEventListener("popstate", this.handleQualificationFilterOnNavigatingBack);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.handleQualificationFilterOnNavigatingBack);
  },
  methods: {
    handleQualificationFilterChanged() {
      filterManagement.resetPagination(this.options)
      filterManagement.setQualificationFilterInUrlQuery(this.qualificationFilter)
      this.debounceGetDataFromApi()
    },
    handleQualificationFilterOnNavigatingBack(event) {
      if (event?.type === 'popstate') {
        this.qualificationFilter = filterManagement.getQualificationFilterFromUrlQuery(this.$route)
        this.debounceGetDataFromApi()
      }
    },
  }
}
