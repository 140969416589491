<template>
  <div>
    <PageTitle
      icon="mdi-arrow-collapse"
      :title="$t('listing.matches.title')"
    ></PageTitle>
    <MatchesList
      class="mt-4"
    ></MatchesList>
  </div>
</template>

<script>
import PageTitle from '@/components/layout/PageTitle.vue'
import MatchesList from '@/components/matching/MatchesList.vue'

export default {
  components: {MatchesList, PageTitle },
}
</script>

<style scoped></style>
