<template>
  <div>
    <v-card class="pa-8 fill-height">
      <v-row justify="center">
        <v-col class="text-left">
          <p class="text-h5" data-cy="candidate_name_header">{{ $t("global.list_filter") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4" class="text-left">
          <p
            class="text--secondary mb-2 body-2"
            v-html="$t('listing.matches.filters.agent-help')"
          />
          <AgentFilter
            v-model="agentFilter"
            data-cy="matchesFilters"
            :mine-text="$t('listing.matches.filters.mine')"
            :all-text="$t('listing.matches.filters.all')"
            :dense="true"
            @input="handleAgentFilterChanged"
          ></AgentFilter>
        </v-col>
        <v-col md="4" class="text-left">
          <p
            class="text--secondary mb-2 body-2"
            v-html="$t('listing.matches.filters.qualified-help')"
          />
          <QualificationFilter
            v-model="qualificationFilter"
            :qualified-text="$t('listing.matches.filters.qualified')"
            :all-text="$t('listing.matches.filters.unqualified')"
            @input="handleQualificationFilterChanged"
          ></QualificationFilter>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4">
          <v-autocomplete
            v-model="selectedEmployerObjs"
            data-cy="employer-autocomplete"
            :items="employerNames"
            :loading="isEmployersLoading"
            :search-input.sync="employerSearch"
            :label="$t('listing.matches.headers.employer')"
            :placeholder="$t('listing.matches.filters.autocomplete_placeholder')"
            :filter="unaccentedFilter"
            item-text="name"
            item-value="id"
            class="employer-autocomplete"
            chips
            deletable-chips
            multiple
            @change="updateEmployersSelection"
          >
            <template v-slot:item="data">
              <!-- Override item slot to allow mask on unaccented search -->
              <div class="v-list-item__action">
                <v-simple-checkbox
                  :ripple="false"
                  :value="data.attrs.inputValue"
                  color="primary"
                  @input="data.on.click" />
              </div>
              <div class="v-list-item__content">
                <div class="v-list-item__title" v-html="unaccentedEmployerMask(data)" />
              </div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col md="4">
          <v-autocomplete
            v-model="selectedCandidateObjs"
            data-cy="candidate-autocomplete"
            :items="candidateNames"
            :loading="isCandidatesLoading"
            :search-input.sync="candidateSearch"
            :label="$t('listing.matches.headers.candidate')"
            :placeholder="$t('listing.matches.filters.autocomplete_placeholder')"
            item-text="full_name"
            item-value="id"
            :filter="unaccentedFilter"
            class="candidate-autocomplete"
            chips
            deletable-chips
            multiple
            @change="updateCandidatesSelection"
          >
            <template v-slot:item="data">
              <!-- Override item slot to allow mask on unaccented search -->
              <div class="v-list-item__action">
                <v-simple-checkbox
                  :ripple="false"
                  :value="data.attrs.inputValue"
                  color="primary"
                  @input="data.on.click" />
              </div>
              <div class="v-list-item__content">
                <div class="v-list-item__title" v-html="unaccentedCandidateMask(data)" />
              </div>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8" class="text--secondary">
          <p v-if="threshold" v-html="$t('listing.matches.threshold', { value: threshold })"/>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-8 mt-4 fill-height">
      <v-row no-gutters class="d-print-none">
        <v-col>
          <DataListTitle
            :title="`${totalItems} ${$t('listing.matches.count')}`"
            :is-loading="loading"
          ></DataListTitle>
        </v-col>
      </v-row>
      <div>
        <v-data-table
          id="matchesDataTable"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          :footer-props="{
            'items-per-page-text': $t('listing.matches.pagination'),
            'items-per-page-options': itemsPerPageOptions
          }"
          multi-sort
          @update:options="handleTableOptionUpdated"
        >
          <template v-slot:item.candidate_first_name="{ item }">
            {{ item.candidate_first_name }} {{ item.candidate_last_name }}
          </template>
          <template v-slot:item.employer_representative_first_name="{ item }">
            {{ item.employer_representative_first_name }}
            {{ item.employer_representative_last_name }}
          </template>
          <template v-slot:item.job_offer_assigned_user.first_name="{ item }">
            {{
              item.job_application_assigned_user
                ? `${item.job_offer_assigned_user.first_name} ${item.job_offer_assigned_user.last_name}`
                : '-'
            }}
          </template>
          <template
            v-slot:item.job_application_assigned_user.first_name="{ item }"
          >
            {{
              item.job_application_assigned_user
                ? `${item.job_application_assigned_user.first_name} ${item.job_application_assigned_user.last_name}`
                : '-'
            }}
          </template>
          <template v-slot:item.created_at="{ value }">
            <span>{{ formatDate(value) }}</span>
          </template>
          <template v-slot:item.score_without_interest="{ value }">
            <div style="max-width: 50px">
              <CircularScore :score="value * 100" />
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="py-2">
              <v-btn
                color="primary"
                class="ml-1"
                small
                @click="showMatchDetails(item.id)"
              >
                {{ $t('listing.matches.details') }}
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>
<script>
import CircularScore from '@/components/common/CircularScore.vue'
import { formatDate } from '@/helpers/dates'
import DataListTitle from '@/components/layout/DataListTitle.vue'
import DataTablesMixin from '@/mixins/dataTablesMixin'

import {mapActions, mapGetters, mapState} from 'vuex'
import RouterMixin from '@/mixins/routerMixin'
import { filterManagement } from "@/services/filters"
import AgentFilter from "@/components/common/AgentFilter.vue";
import QualificationFilter from "@/components/common/QualificationFilter.vue";
import AgentFilterMixin from "@/mixins/agentFilterMixin";
import CandidateFilterMixin from "@/mixins/candidateFilterMixin";
import EmployerFilterMixin from "@/mixins/employerFilterMixin";
import unaccented from '@/helpers/texts'
import QualificationFilterMixin from "@/mixins/qualificationFilterMixin";

export default {
  components: {DataListTitle, CircularScore, AgentFilter, QualificationFilter },
  mixins: [
    DataTablesMixin,
    RouterMixin,
    AgentFilterMixin,
    CandidateFilterMixin,
    EmployerFilterMixin,
    QualificationFilterMixin,
  ],
  data() {
    return {
      headers: [
        {
          text: this.$t('listing.matches.headers.job_name'),
          value: 'offer_name',
          align: 'start'
        },
        {
          text: this.$t('listing.matches.headers.employer'),
          value: 'employer_name'
        },
        {
          text: this.$t('listing.matches.headers.employer_representative'),
          value: 'representative_name'
        },
        {
          text: this.$t('listing.matches.headers.offer_agent'),
          value: 'offer_agent_name'
        },
        {
          text: this.$t('listing.matches.headers.candidate'),
          value: 'candidate_name'
        },
        {
          text: this.$t('listing.matches.headers.job_application_agent'),
          value: 'application_agent_name'
        },
        {
          text: this.$t('listing.matches.headers.matching_date'),
          value: 'created_at'
        },
        {
          text: this.$t('listing.matches.headers.score'),
          value: 'score_without_interest'
        },
        {
          value: 'actions',
          align: 'right'
        }
      ],
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('matching', ['storedFilters']),
    ...mapGetters('page', ['dataIsLoading']),
    threshold() {
      if (this.$store.state.matching.matches.threshold) {
        const val = this.$store.state.matching.matches.threshold
        if (val > 0 && val < 1) {
          return (val * 100).toFixed(0)
        }
      }
      return 0
    }
  },

  methods: {
    ...mapActions('matching', ['getMatches']),
    formatDate,
    showMatchDetails(id) {
      this.routerPush(`/matching/${id}`)
    },
    unaccentedFilter(_, search, itemText) {
      return unaccented(itemText.toLocaleLowerCase())
        .indexOf(unaccented(search.toLocaleLowerCase())) > -1
    },
    getMaskedCharacters(text, search) {
      const searchInput = unaccented((search || '').toString().toLocaleLowerCase())
      const index = unaccented(text.toLocaleLowerCase()).indexOf(searchInput)
      if (index < 0) return { start: text, middle: '', end: '' }

      const start = text.slice(0, index)
      const middle = text.slice(index, index + searchInput.length)
      const end = text.slice(index + searchInput.length)
      return `${ start }<span class="v-list-item__mask">${middle}</span>${end}`
    },
    unaccentedCandidateMask(data) {
      return this.getMaskedCharacters(data.item.full_name, data.parent.searchInput)
    },
    unaccentedEmployerMask(data) {
      return this.getMaskedCharacters(data.item.name, data.parent.searchInput)
    },
    getDataFromApi() {
      this.loading = true

      const args = {
        ...filterManagement.getTableOptionsFromUrlQuery(this.$route),
        mine: filterManagement.getAgentFilterFromUrlQuery(this.$route),
        candidates: filterManagement.getCandidatesFilterFromUrlQuery(this.$route).join(),
        employers: filterManagement.getEmployersFilterFromUrlQuery(this.$route).join(),
        qualified: filterManagement.getQualificationFilterFromUrlQuery(this.$route)
      }

      this.getMatches(args).then(data => {
        this.items = data.items
        this.totalItems = data.count
        this.loading = false
      })
    },
  }
}
</script>
<style scoped></style>
