import {filterManagement} from "@/services/filters"
import { mapActions} from 'vuex'
import debounce from "lodash.debounce";

export default {
  data() {
    return {
      employersFilter: filterManagement.getEmployersFilterFromUrlQuery(this.$route),
      selectedEmployerObjs: [],
      employerNames:[],
      isEmployersLoading: false,
      employerSearch: null,
      debounceEmployerSearch: null,
    }
  },
  methods: {
    ...mapActions('employers', ['getEmployerNames', 'getEmployerNamesByIds']),
    handleEmployerFilterChanged() {
      filterManagement.resetPagination(this.options)
      filterManagement.setEmployersFilterInUrlQuery(this.employersFilter)
      this.debounceGetDataFromApi()
    },
    updateEmployersSelection(ids) {
      // Only to keep previous selections on new searches
      this.selectedEmployerObjs = []
      this.employerNames.forEach((obj) => {
        if (ids.includes(obj.id)) {
          this.selectedEmployerObjs.push(obj)
        }
      })

      // Emitting actual selection
      this.employersFilter = ids
      this.handleEmployerFilterChanged()
    },
    getEmployerNamesFromAPI() {
      if (this.isEmployersLoading) return
      this.isEmployersLoading = true

      if (this.employerSearch.length < 2) {
        // Don't search for less than two characters
        this.isEmployersLoading = false
      } else {
        // Search in API
        this.getEmployerNames(this.employerSearch)
          .then(res => {
            this.employerNames = res.concat(this.selectedEmployerObjs)
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isEmployersLoading = false))
      }
    },
    handleEmployerFilterOnNavigatingBack(event) {
      if (event?.type === 'popstate') {
        this.isNavigatingBack = true
        this.options = filterManagement.getTableOptionsFromUrlQuery(this.$route)
        this.employersFilter = filterManagement.getEmployersFilterFromUrlQuery(this.$route)
        this.debounceGetDataFromApi()
      }
    },
  },
  async mounted() {
    this.debounceEmployerSearch = debounce(this.getEmployerNamesFromAPI, 600)
    window.addEventListener("popstate", this.handleEmployerFilterOnNavigatingBack);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.handleEmployerFilterOnNavigatingBack);
  },
  watch: {
    employerSearch: {
      handler() {
        this.debounceEmployerSearch()
      },
      deep: false,
    },
    employersFilter: {
      async handler() {
        let employers = []
        if (this.employersFilter && this.employersFilter.length > 0)
          employers = await this.getEmployerNamesByIds(this.employersFilter)
        this.selectedEmployerObjs = employers
        this.employerNames = employers
      },
      immediate: true
    },
  }
}
